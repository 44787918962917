<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('List_Transfer_In') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block pt-2"
          :to="{name:'Create-transfer-list'}"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>
          <span>{{ $t('create_transfer_list') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none pt-1"
          fab
          icon
          outlined
          :to="{name:'Create-transfer-list'}"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            outlined
            dense
            :label="$t('search')"
            :placeholder="$t('wordingSearch')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.transfer_id_pri`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.transfer_id`]="{ item }">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'DetailTransferIn', params: { id: item.transfer_id_pri } }"
          >
            {{ item.transfer_id }}
          </router-link>
        </template>
        <template v-slot:[`item.shop_id_name_to`]="{ item }">
          {{ item.shop_id_name_to }} ({{ item.shop_id_to }})
        </template>
        <template v-slot:[`item.transfer_create`]="{ item }">
          <ConversDateTime :datetime="item.transfer_create" />
        </template>
        <template v-slot:[`item.transfer_status_id`]="{ item }">
          <TransferStatus :status="+item.transfer_status_id" />
        </template>
        <template v-slot:[`item.user_fullname_to`]="{ item }">
          <span v-if="item.transfer_status_id == 1">
            {{ $t("waiting_check") }}
          </span>
          <span v-else>
            {{ item.user_fullname_to }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'DetailTransferIn', params: { id: item.transfer_id_pri } }"
          >
            <v-tooltip
              top
              color="#212121"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("detail") }}</span>
            </v-tooltip>
          </router-link>
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'ReceiptTransferIn', params: { id: item.transfer_id_pri } }"
          >
            <v-tooltip
              color="#212121"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  :disabled="item.transfer_status_id != '1'"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCheckCircleOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("receive") }}</span>
            </v-tooltip>
          </router-link>

          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.transfer_status_id=='3'"
                @click="print(item.transfer_id_pri)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("print") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus, mdiPrinter, mdiInformationOutline, mdiCheckCircleOutline,
} from '@mdi/js'
import useTransferInList from './useTransferInList'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import TransferStatus from '@/components/basicComponents/TransferStatus.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    ConversDateTime,
    Pagination,
    TransferStatus,
  },
  setup() {
    return {
      ...useTransferInList(),
      icons: {
        mdiPlus, mdiPrinter, mdiInformationOutline, mdiCheckCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
